<template>
  <div class="content">
    <div class="main">
      <service-and-support-nav :active-index="1" />

      <div class="upView">
        <div class="title">下載中心</div>
        <div class="subtitle">
          您可找到Bareiss產品的操作手冊、產品型錄、圖檔、各類表格等檔案。
        </div>
      </div>

      <div class="screenView">
        <div class="tipTxt">請選擇您使用的產品機種或檔案類別</div>
        <div class="form">
          <el-select
            v-model="screen_one"
            placeholder="請選擇產品機種"
            @change="getData"
            clearable
          >
            <el-option
              v-for="item in screen_one_options"
              :key="item.id"
              :label="item.title"
              :value="item.id"
            >
            </el-option>
          </el-select>
          <el-select
            v-model="screen_two"
            placeholder="請選擇檔案類別"
            @change="getData"
            clearable
          >
            <el-option
              v-for="item in screen_two_options"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </div>
      </div>

      <div class="tableView">
        <div class="choiceTxt">
          下列產品的搜尋結果：
          <p>
            {{ search_result(screen_two) }}
          </p>
        </div>
        <el-table :data="tableData" :header-cell-style="header_row_class">
          <el-table-column
            prop="pic"
            label="格式"
            :width="stores.state.screenWidth > 768 ? 180 : 60"
          >
            <template slot-scope="scope">
              <img
                v-if="scope.row.re_extension == 'excel'"
                src="@/assets/images/excel.jpg"
                alt=""
              />
              <img
                v-if="scope.row.re_extension == 'pdf'"
                src="@/assets/images/pdf.jpg"
                alt=""
              />
              <img
                v-if="scope.row.re_extension == 'ppt'"
                src="@/assets/images/ppt.jpg"
                alt=""
              />
              <img
                v-if="scope.row.re_extension == 'txt'"
                src="@/assets/images/txt.jpg"
                alt=""
              />
              <img
                v-if="scope.row.re_extension == 'word'"
                src="@/assets/images/word.jpg"
                alt=""
              />
            </template>
          </el-table-column>
          <el-table-column
            prop="type"
            label="檔案類別"
            :width="stores.state.screenWidth > 768 ? 180 : 100"
          >
          </el-table-column>
          <el-table-column prop="title" label="標題"></el-table-column>
          <el-table-column
            prop="language"
            label="語言"
            width="180"
            v-if="stores.state.screenWidth > 768"
          >
          </el-table-column>
          <el-table-column
            prop="size"
            label="尺寸"
            width="180"
            v-if="stores.state.screenWidth > 768"
          >
          </el-table-column>
          <el-table-column
            prop="fileUrl"
            label="下載"
            :width="stores.state.screenWidth > 768 ? 180 : 100"
          >
            <template slot-scope="scope">
              <el-link
                type="primary"
                @click="downloadFun(scope.row.attachment_url)"
                >下載</el-link
              >
            </template>
          </el-table-column>
        </el-table>
      </div>

      <contact-us />
    </div>
  </div>
</template>

<script>
import ServiceAndSupportNav from "@/components/service-and-support/nav";
import contactUs from "@/components/contact-us";
import { downloadFile } from "@/common/utils";
import store from "@/store";
export default {
  name: "DownloadCenter",
  components: {
    ServiceAndSupportNav,
    contactUs,
  },
  data() {
    return {
      screen_one_options: [],
      screen_one: "",
      screen_two_options: [],
      screen_two: "",
      tableData: [],
      stores: store,
    };
  },
  mounted() {
    this.downloadCenter_screen_one_fun();
    this.downloadCenter_screen_two_fun();
    this.getData();
  },
  methods: {
    /**
     * 过滤选择
     */
    search_result(id) {
      if (id != "") {
        return this.screen_two_options.filter((item) => item.id == id)[0].name;
      } else {
        return "";
      }

      // return this.screen_two_options.filter((item) => item.id == id);
    },
    /**
     * 设置表头样式
     */
    header_row_class({ row, rowIndex }) {
      if (rowIndex === 0) {
        return "background:#e8edf1;color:#333333;font-size: 16px;";
      } else {
        return;
      }
    },
    /**下载文件 */
    downloadFun(url) {
      // downloadFile(url);
      this.$router.push(`/product_consulting`);
    },
    /**筛选1 */
    downloadCenter_screen_one_fun() {
      this.$http.productSelectListAll().then((res) => {
        if (res.code == 200) {
          this.screen_one_options = res.data;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    /**筛选2 */
    downloadCenter_screen_two_fun() {
      this.$http.downloadCenter_screen_two().then((res) => {
        if (res.code == 200) {
          this.screen_two_options = res.data;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    /**获取表格数据 */
    getData() {
      this.$http
        .downloadCenter({
          product_id: this.screen_one == "" ? 0 : this.screen_one,
          model_id: this.screen_two == "" ? 0 : this.screen_two,
        })
        .then((res) => {
          if (res.code == 200) {
            this.tableData = res.data;
          } else {
            this.$message.error(res.msg);
          }
        });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/style/variables.scss";
.main {
  width: 1400px;
  margin: 0 auto;
  @include respond-to(max1439) {
    width: 95%;
  }
  .upView {
    margin-bottom: 50px;
    @include respond-to(lg) {
      margin-bottom: 30px;
    }
    @include respond-to(sm) {
      margin-bottom: 15px;
    }
    .title {
      font-size: 40px;
      color: #1a1a1a;
      margin-bottom: 26px;
      @include respond-to(lg) {
        font-size: 30px;
        margin-bottom: 10px;
      }
      @include respond-to(sm) {
        font-size: 16px;
      }
    }
    .subtitle {
      font-size: 16px;
      color: #333333;
      @include respond-to(lg) {
        font-size: 14px;
      }
      @include respond-to(sm) {
        font-size: 12px;
      }
    }
  }
  .screenView {
    margin-bottom: 60px;
    @include respond-to(sm) {
      margin-bottom: 15px;
    }
    .tipTxt {
      font-size: 16px;
      color: #333333;
      margin-bottom: 20px;
      @include respond-to(sm) {
        margin-bottom: 10px;
        font-size: 14px;
      }
    }
    .form {
      @include respond-to(sm) {
        display: flex;
      }
      > * {
        width: 300px;
        margin-right: 20px;
        @include respond-to(sm) {
          width: 45%;
        }
      }
    }
  }
  .tableView {
    margin-bottom: 100px;
    @include respond-to(sm) {
      margin-bottom: 30px;
    }
    .choiceTxt {
      display: flex;
      align-items: center;
      font-size: 26px;
      color: #333333;
      margin-bottom: 30px;
      @include respond-to(lg) {
        font-size: 18px;
        margin-bottom: 15px;
      }
      p {
        font-size: 20px;
        color: #1a1a1a;
        padding-left: 30px;
      }
    }
  }
}
/deep/ .el-table .cell {
  font-size: 16px;
  color: #333333;
}
</style>